.footer-container {
    width: 100%;
    height: 7.5vh;
    position: absolute;
    bottom: 0;
    z-index: 999;
    background: rgba(8, 5, 20, 0);
    .footer-main{
        width: 100%;
        height: 100%;
        color: #959595;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        .footer-left{
            position: absolute;
            left: 84px
        }
        .footer-right{
            position: absolute;
            right: 84px
        }
    }
}