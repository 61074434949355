$fontsize-25: 25px;
$fontsize-32: 32px;
$fontsize-42: 42px;
$fontsize-56: 56px;
$fontsize-20: 20px;
$fontsize-30: 30px;
$fontsize-22: 20px;
$fontsize-72: 72px;
// 标题的高度
$about-title-width: 268px;
$mint-title-width: 248px;
$buy-title-width: 313px;
$faqs-title-width: 126px;
$roadmap-title-width: 445px;

$height-84: 84px;
$height-76: 76px;

$height-100: 100px;