@import "@style/var.scss";
.header-container {
  height: 7.5vh;
  line-height: $height-100;
  position: sticky;
  top: 0;
  z-index: 999;
  background: rgba(8, 5, 20, 0);
  .header-main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-left {
      position: absolute;
      left: 32px;
      .logo {
        width: 132px;
        height: 78px;
      }
    }
    .header-center {
      .ant-anchor {
        display: flex;
        height: 7.5vh;
        margin-right: 32px;
        line-height: $height-100;
        overflow: hidden;
        .ant-anchor-ink {
          display: none;
        }        

        .ant-anchor-link {
          // margin-right: 60px;
          // margin-left: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .ant-anchor-link-title {
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            color: #fff;
            opacity: 0.8;
            &:hover{
              opacity: 1;
            }
          }
          
          &.ant-anchor-link-active {
            .ant-anchor-link-title-active {
              opacity: 1;
              &::after {
                position: relative;
                top: -37px;
                display: block;
                width: 46px;
                height: 3px;
                margin: 0 auto;
                background: #7c5bff;
                content: " ";
              }
            }
          }
        }
      }
    }
  }
}
